import React from 'react';
import Skill from  './Skill'
import Product from './Product'
import Profile from './Profile'
import { Container} from "reactstrap";

class Home extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        products: {}
      }
    }

    render(){
        const { service } = this.props
        return <Container>
            <Skill service={service}></Skill>
            <br/>
            <Profile service={service}></Profile>
            <Product service={service}></Product>
        </Container>
    }
}

export default Home;