import detectBrowserLanguage from 'detect-browser-language'

class DataSourceService {


    findPersonalInfo = () => {
        console.log(detectBrowserLanguage())
        return this.fetchDatasourceJsonFile("personal")
    }

    getPosts = () => {
        return this.fetchDatasourceJsonFile("posts")
    }

    getProducts = () => {
        return this.fetchDatasourceJsonFile("products");
    }

    findProfile = () => {
        return this.fetchDatasourceJsonFile("profile");
    }

    getStudies = () => {
        return this.fetchDatasourceJsonFile("studies");
    }

    fetchDatasourceJsonFile(fileName) {
        return fetch(`/datasource/${fileName}.json`)
    }
}

export default DataSourceService