import React from 'react';
import { Jumbotron, Container } from "reactstrap";

class ProfileSummary extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      profile: {}
    }
  }

  componentDidMount() {
    const { service } = this.props
    service.findProfile()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ...this.state, profile: data })
      })
      .catch(console.error)
  }

  render() {
    const { profile } = this.state
    return <Jumbotron>
      <Container>
        <h1 className="display-3">{profile.title}</h1>
        <p className="lead">{profile.summary}</p>
      </Container>
    </Jumbotron>;
  }
}

export default ProfileSummary;