import React from 'react';
import { Container, Row, Col, Media } from 'reactstrap';
import moment from 'moment';

class Courses extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      profile: {}
    }
  }

  componentDidMount() {
    const { service } = this.props
    service.getStudies()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ...this.state, profile: data })
      })
      .catch(console.error)
  }

  render() {
    const { profile } = this.state
    return <Container>
      <Row>
        <Col>
          {profile && profile.courses && profile.courses.map(function (course, i) {
            return <Media key={i}>
              <Media left top href={course.url}>
                <Media object src={course.logo} alt={course.title} />
              </Media>
              <Media body>
                <Media heading>
                  <a href={course.url}>{course.title}</a>
                </Media>
                <Row>
                  <Col>{moment(course.issueDate).format('MMM YYYY')}</Col>
                </Row>
                <Row>
                  <Col>{course.issuer}</Col>
                </Row>
              </Media>
            </Media>
          })}
        </Col>
      </Row>
    </Container>
  }
}

export default Courses;