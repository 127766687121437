import React, {Component} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import './App.css';
import TopBar from './components/TopBar';
import Profile from './components/Profile';
import Posts from './components/Posts';
import Footer from './components/Footer';
import Home from './components/Home';
import ProfileSummary from "./components/ProfileSummary";
import DatasourceService from './services/DataSourceService';


class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
    this.service = new DatasourceService();
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <div className="App">
        <Router>
          <TopBar service={this.service}/>
          <ProfileSummary service={this.service}/>
          <Route exact={true} path="/" render={(props) => <Home service={this.service}/>} />
          <Route path="/posts" render={(props) => <Posts service={this.service}/>} />
          <Route path="/profile" render={(props) => <Profile service={this.service}/>} />
        </Router>
        <Footer service={this.service}/>
      </div>
    );
  }
}

export default App;