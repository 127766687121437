import React from 'react';
import { Container, Row, Col, Media } from "reactstrap";

class Product extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            products: {}
        }
    }

    componentDidMount() {
        const { service } = this.props
        service.getProducts()
            .then((res) => res.json())
            .then((data) => {
                this.setState({ ...this.state, products: data })
            })
            .catch(console.error)
    }

    render() {
        const { products } = this.state
        return <Container>
            <h4 style={{ borderBottom: "1px solid #CCC" }}>Products</h4>
            <Row>

                {products && products.length > 0 && products.map(function (product, i) {

                    return (
                        <Col sm="12" md="6">
                            <Media>
                                <Media left top href={product.url}>
                                    <Media object src={product.logo} alt={product.title} />
                                </Media>
                                <Media body>
                                    <Media heading>
                                        <a href={product.url} target="_blank">{product.title}</a>
                                        <br />
                                        <div class="productDescription">
                                            {product.description}
                                        </div>

                                    </Media>
                                </Media>
                            </Media>

                        </Col>
                    )
                })}
            </Row>
        </Container>
    }
}

export default Product;