import React from 'react';
import { Container, Row, Col, Media } from 'reactstrap';
import moment from 'moment';

class Certifications extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      profile: {}
    }
  }

  componentDidMount() {
    const { service } = this.props
    service.getStudies()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ...this.state, profile: data })
      })
      .catch(console.error)
  }

  render() {
    const { profile } = this.state
    return <Container>
      <Row>
        <Col>
          {profile && profile.certifications && profile.certifications.map(function (certification, i) {
            const verification = certification.verificationLink ?
              <Row>
                <Col>
                  <a className="certificateLink" href={certification.verificationLink}>See certificate</a>
                </Col>
              </Row> : "";
            return <Media key={i}>
              <Media left top href={certification.url}>
                <Media object src={certification.logo} alt={certification.title} />
              </Media>
              <Media body>
                <Media heading>
                  <a href={certification.url}>{certification.title}</a>
                </Media>
                <Row>
                  <Col>{moment(certification.issueDate).format('MMM YYYY')} - {(certification.expiryDate) ? moment(certification.expiryDate).format('MMM YYYY') : 'Present'}</Col>
                </Row>
                <Row>
                  <Col>{certification.issuer}</Col>
                </Row>
                {verification}
              </Media>
            </Media>
          })}
        </Col>
      </Row>
    </Container>
  }
}

export default Certifications;