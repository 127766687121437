import React from 'react';
import { Container, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
//import profilePic from '../profilePic.jpg';

class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  componentDidMount() {
    const { service } = this.props
    service.findPersonalInfo()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ...this.state, personalInfo: data })
      })
      .catch(console.error)
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const personal = this.state.personalInfo
    return (
      <Navbar color="dark" dark expand="md">
        <Container>
          <NavbarBrand href="/">
            {personal &&
            <div>
              <img src={personal.pic} className="profile-pic" alt="Adam M. Gamboa G"/>
              <span>{personal.name}</span>
              </div>
            }
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/" class="top-nav-link" >Home</NavLink>
              </NavItem>
              <NavItem>
                <a href="/blog/" target="_blank" class="nav-link top-nav-link">Blog</a>
              </NavItem>
              <NavItem>
                <NavLink href="/posts/" class="top-nav-link">Other Posts</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default TopBar;