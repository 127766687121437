import React from 'react';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithubSquare, faTwitterSquare, faYoutube, faDev } from '@fortawesome/free-brands-svg-icons'
import GoogleAd from './GoogleAd';

class Footer extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      icons: {
        gitHub: faGithubSquare,
        linkedIn: faLinkedin,
        twitter: faTwitterSquare,
        youtube: faYoutube,
        dev: faDev
      },
      personalInfo: {}
    }
  }

  componentDidMount() {
    const { service } = this.props
    service.findPersonalInfo()
      .then((res) => res.json())
      .then((data) => {
        this.setState({ ...this.state, personalInfo: data })
      })
      .catch(console.error)
  }

  render() {
    const { icons } = this.state
    const personal = this.state.personalInfo
    return (
      <div>
        <footer>
          <hr />
          <Container>
            {personal && personal.pages && personal.pages.map(function (page, i) {
              return (
                <a href={page.url} alt={page.site} target="_blank">
                  <FontAwesomeIcon icon={icons[page.icon]} size="2x" />
                </a>
              );
            })}
          </Container>
        </footer>
        <GoogleAd slot="7204060845" googleAdId="ca-pub-8579383911634142"/>
      </div>
    )
  }
}

export default Footer;