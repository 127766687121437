import React from 'react';
import { Container, Row, Col, Media } from "reactstrap";

class Skill extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        products: {}
      }
    }

    render(){
        return <Container>
            <h4 style={{borderBottom:"1px solid #CCC"}}>Skills</h4>
            <Row>
                <Col sm="12" md="6" lg="4">
                    <h5>Java</h5>
                    <ul>
                        <li>Java SE (5-17)</li>
                        <li>Java EE / Jakarta EE</li>
                        <li>MicroProfile</li>
                        <li>Spring Framework (Spring Core, Spring boot, Spring Data)</li>
                        <li>Dropwizard</li>
                        <li>Quarkus</li>
                    </ul>
                    <h5>Other Back-End</h5>
                    <ul>
                        <li>Scala</li>
                        <li>Node JS (Express, NestJS)</li>
                        <li>Python (Flask)</li>
                    </ul>
                </Col>
                <Col sm="12" md="6" lg="4">
                    <h5>Front-End</h5>
                    <ul>
                        <li>HTML5, CSS, JavaScript</li>
                        <li>React JS</li>
                        <li>JQuery</li>
                        <li>Bootstrap</li>
                    </ul>
                    <h5>Streaming/Messaging/Queue</h5>
                    <ul>
                        <li>JMS</li>
                        <li>Apache Spark</li>
                    </ul>

                    <h5>Design and Architectures</h5>
                    <ul>
                        <li>Microservices</li>
                        <li>Rest API</li>
					    <li>Continous Integration/Continous Delivery</li>
                        <li>Serveless: AWS Lambda, Fn Project</li>
                        <li>Clean Architecture</li>
                    </ul>
                    
                </Col>

                <Col sm="12" md="6" lg="4">
                    
                    <h5>Container and Orchestration</h5>
                    <ul>
                        <li>Docker</li>
                        <li>Docker Compose</li>
                        <li>Kubernetes</li>
						<li>Helm</li>
                    </ul>
                    <h5>Cloud Provider</h5>
                    <ul>
                        <li>AWS</li>
                    </ul>
                    <h5>Agile Scrum</h5>
                </Col>
            </Row>
        </Container>
    }
}

export default Skill;